.flights_table {
  border: 2px solid red;
}
.table > :not(caption) > * > * {
  border: 0px;
}

/* FlightList.css */
.flights-table {
  width: 100%;
}

@media (max-width: 768px) {
  .flights-table {
    display: block;
    overflow-x: auto;
  }

  .flights-table td,
  .flights-table th {
    white-space: nowrap;
  }
}

/* CSS */
input::placeholder {
  font-size: 12px; /* Set your desired font size here */
}
