.cross_icon_modal {
  position: absolute;
  top: -20px;
  right: -11px;
  color: #fff;
  cursor: pointer;
}
.icon_div {
  position: absolute;
  top: 6px;
  right: 11px;
}

.border_footer_continue {
  height: 0.1px;
  background: var(--darkDim);
  flex-grow: 1;
}
.social_ic_div {
  border: 0.1px ridge var(--dimBorder);
  padding: 1px 11px 5px 10px;
  border-radius: 4px;
}
.textfield_phone_number {
  border: none;
  height: 100%;
  padding: 4px 4px 4px 4px;
}
.textfield_phone_number:focus {
  border: none;
  outline: 0px;
}
.number_selctor {
  padding-left: 5px;
  padding-right: 5px;
}
.traveller_button {
  border-radius: 50%;
  padding: 1px 7px;
}
