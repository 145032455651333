:root {
  --primary: #f83557;
  --primary_bg: #feeaee;
  --secondary: black;
  --dim: #7c7c7c;
  --dimBorder: #e5e5e5;
  --darkDim: #353b44;
  --green_Active: #12b3a8;
  --footer_bg: #060721;
  --third: #3268c4;
  --cont_background: #fcfcfc;
  --reactivate_bg: #e4fef1;
  --graph_bg: #1e2b40;
  --green_active: #00e676;
  --trav_detail: #f5f7fc;
}

.primary_txt {
  color: var(--primary);
}
.primary_txt_bg {
  background-color: var(--primary_bg);
}
.cont_bg {
  background: var(--cont_background);
}

.dim_txt {
  color: var(--dim);
}
.dim_bg {
  background: var(--dim);
}

.dimBorder_texxt {
  color: var(--dimBorder);
}

.bg_primary {
  background: var(--primary);
}

.green_bg {
  background: var(--green_Active);
}
.footer_bg {
  background-color: var(--footer_bg);
}

.third_txt {
  color: var(--third);
}

.third_txt_bg {
  background-color: var(--third);
}
.reactivate_bg {
  background-color: var(--reactivate_bg);
}
.react_col {
  color: var(--green_Active);
}

.graph_bg {
  background-color: var(--graph_bg);
}

.trav_det_back {
  background-color: var(--trav_detail);
}
