.banner_cont {
  position: relative;
  height: 438px;
}
.banner_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.banner_abs_cont {
  position: absolute !important;
  top: 20px;
  transform: translateX(-50%);
  left: 50%;
}
.way_selctor {
  /* background: red; */
  display: inline;
}
.trip_selector_box {
  /* position: absolute; */
  margin-top: -100px;
  /* width: 100%; */

  /* opacity: 0.7; */
}

.tab_selector {
  padding: 10px 20px;
}

.tab_selector:first-child {
  /* background-color: var(--primary); */
  border-top-left-radius: 8px;
}

.tab_selector:last-child {
  border-top-right-radius: 8px;
}
.trip_details_box {
  padding: 10px;
  background: #fff;
  border-radius: 8px;
  border-top-left-radius: 0px;
}
.border_style_1 {
  border: 0.3px solid var(--dimBorder);
}
.trip_box {
  border-radius: 4px;
  padding: 10px;
  height: 100%;
}
.arrow_for_middle_two_row {
  position: absolute;
  right: -17px;
  top: 35%;
  border-radius: 50%;
  padding: 0px 7px;
  /* z-index: 9999999; */
  background: #fff;
}
.icon_cancel_return_date {
  background: var(--dimBorder);
  border-radius: 50%;
  padding: 1px 3px;
  position: absolute;
  top: 10px;
  right: 10px;
}
.how_its_works_box {
  border-radius: 8px;
}
.how_its_works_text_box {
  background-color: var(--darkDim);
  padding: 10px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.how_it_wor_image {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.bell_icon_div {
  background: #1a3948;
  border-radius: 50%;
  padding: 5px 10px;
}
.profile_drop_cont {
  background: #fff;
  position: absolute;
  padding: 10px;
  top: 46px;
  right: 0px;
}
