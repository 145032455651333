.icon_bg_footer {
  background: #15162e;
  display: inline;
  border-radius: 50%;
  padding: 30px;
}
.footer_social_icon_divb {
  border-radius: 50%;
  padding: 3px 10px;
}
