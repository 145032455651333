.cam_icon {
  position: absolute;
  bottom: -10px;
  left: 14px;
  border-radius: 50%;
  padding: 0px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.siderbar_icon_div {
  display: flex;
  align-items: center;
  justify-content: center;
  background: red;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.active_side_bar_icon {
  background: linear-gradient(
    90deg,
    rgb(20, 146, 220) 0%,
    rgb(170, 104, 241) 100%
  );
}

.non_activeIcon_color {
  color: rgb(170, 104, 241) 100%;
}

.non_active_icon_bg {
  background: #f2f0fd;
}
.active_arrow {
  height: 50px;
  position: absolute;
  left: 0px;
}
