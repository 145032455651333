* {
  font-family: "Rubik", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
strong,
em,
i,
b,
u,
s,
a {
  margin-bottom: 0;
}
/* Font Size */
.fs_10 {
  font-size: 10px;
}

.fs_11 {
  font-size: 11px;
}
.fs_12 {
  font-size: 12px;
}
.fs_20 {
  font-size: 20px;
}
.fs_14 {
  font-size: 14px;
}
.fs_15 {
  font-size: 15px;
}
/* Font Weight */

.fw_500 {
  font-weight: 500;
}
.fw_700 {
  font-weight: 700;
}

/* Utilities */

.pointer {
  cursor: pointer;
}
.relative {
  position: relative;
}
.o_auto {
  overflow: auto;
}

/* Margins */

.mt_min_100 {
  margin-top: -100px;
}

.slick-next,
.slick-prev {
  display: none !important;
}

.opac_07 {
  opacity: 0.7;
}

/* Border Radius */
.br_4 {
  border-radius: 4px;
}

.not_active {
  opacity: 0.7;
  pointer-events: none;
}

/* body {
  background: red;
} */
.br_8 {
  border-radius: 8px;
}
